<!-- 系统设置 系统配置 系统配置 -->
<template>
  <scroll-layout>
    <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="content">
        <ykc-detail-plus class="content-card">
          <div slot="header" class="clearfix-title">
            <span>系统配置</span>
          </div>
          <ykc-form-item
            label="LOGO选择"
            prop="logo"
            bottomTip="温馨提示：建议上传尺寸为80px*73px的jpg/jpeg/png文件，且不超过1M"
            class="img-error">
            <ykc-upload
              :size="1"
              ref="logoFile"
              @handleRemove="handleRemoveLogo"
              @handleChange="fileUpload"></ykc-upload>
          </ykc-form-item>
          <ykc-form-item
            label="登录页LOGO"
            prop="loginLogo"
            bottomTip="温馨提示：建议上传尺寸为180px*46px的jpg/jpeg/png文件，且不超过1M"
            class="img-error">
            <ykc-upload
              class="login-logo-custom"
              :size="1"
              ref="loginLogoFile"
              @handleRemove="handleRemoveLoginLogo"
              @handleChange="fileUploadLogin"></ykc-upload>
          </ykc-form-item>
          <!-- <ykc-form-item label="支付优惠配置" class="item-bottom" required>
            <div class="groupInfo">
              <YkcFormItem prop="alipayDiscountType">
                <YkcDropdown
                  :data="alipayDiscount"
                  v-model="ruleForm.alipayDiscountType"
                  :clearable="false"
                  @change="changeAliDiscount">
                  <template slot="prepend">支付宝支付</template>
                </YkcDropdown>
              </YkcFormItem>
              <YkcFormItem prop="alipayDiscount">
                <YkcInput
                  maxlength="50"
                  :disabled="ruleForm.alipayDiscountType === '0'"
                  placeholder="优惠说明"
                  v-model="ruleForm.alipayDiscount"></YkcInput>
              </YkcFormItem>
              <YkcFormItem prop="wxDiscountType">
                <YkcDropdown
                  :data="wxDiscount"
                  v-model="ruleForm.wxDiscountType"
                  :clearable="false"
                  @change="changeWxDiscount">
                  <template slot="prepend">微信支付</template>
                </YkcDropdown>
              </YkcFormItem>
              <YkcFormItem prop="wxDiscount">
                <YkcInput
                  maxlength="50"
                  :disabled="ruleForm.wxDiscountType === '0'"
                  placeholder="优惠说明"
                  v-model="ruleForm.wxDiscount"></YkcInput>
              </YkcFormItem>
            </div>
          </ykc-form-item> -->
        </ykc-detail-plus>
      </div>
      <!-- <div class="content">
        <ykc-detail-plus class="box-card">
          <div slot="header" class="clearfix-title">
            <span>微信免密支付配置</span>
          </div>
          <ykc-form-item prop="wxEnable" label="是否启用免密" required>
            <ykc-radio
              :data="enableList"
              v-model="ruleForm.wxEnable"
              @change="changeWxEnable"></ykc-radio>
          </ykc-form-item>
          <template v-if="ruleForm.wxEnable === '0'">
            <ykc-form-item class="form-item-width" label="AppId" prop="wxAppId">
              <ykc-input
                v-model="ruleForm.wxAppId"
                maxlength="30"
                placeholder="请输入AppId"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="App秘钥" prop="wxAppCrack">
              <ykc-input
                v-model="ruleForm.wxAppCrack"
                maxlength="500"
                placeholder="请输入App秘钥"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="小程序AppId" prop="wxAppletAppid">
              <ykc-input
                v-model="ruleForm.wxAppletAppid"
                maxlength="30"
                placeholder="请输入小程序AppId"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="小程序秘钥" prop="wxAppletAppidCrack">
              <ykc-input
                v-model="ruleForm.wxAppletAppidCrack"
                maxlength="500"
                placeholder="请输入小程序秘钥"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="商户号" prop="wxMerchantNo">
              <ykc-input
                v-model="ruleForm.wxMerchantNo"
                maxlength="30"
                placeholder="请输入商户号"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="商户秘钥" prop="wxMerchantCrack">
              <ykc-input
                v-model="ruleForm.wxMerchantCrack"
                maxlength="500"
                placeholder="请输入商户秘钥"></ykc-input>
            </ykc-form-item>
            <ykc-form-item
              class="file-error"
              label="授权证书"
              prop="authCertificate"
              bottomTip="温馨提示：只能上传p12文件，且不超过5M">
              <ykc-upload
                type="p12"
                :size="5"
                ref="authCertificate"
                :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
                @handleChange="authCertificate"
                @handleRemove="handleRemove('authCertUrl')"></ykc-upload>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="商户私钥" prop="wxMerchantPrivateCrack">
              <ykc-input
                v-model="ruleForm.wxMerchantPrivateCrack"
                maxlength="500"
                type="textarea"
                placeholder="请输入商户私钥"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="API证书序号" prop="wxApiCertSeq">
              <ykc-input
                v-model="ruleForm.wxApiCertSeq"
                maxlength="30"
                placeholder="请输入API证书序号"></ykc-input>
            </ykc-form-item>

            <ykc-form-item
              class="file-error"
              label="平台证书"
              prop="platformCertificate"
              bottomTip="温馨提示：只能上传pem文件，且不超过5M">
              <ykc-upload
                type="pem"
                :size="5"
                ref="platformCertificate"
                :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
                @handleChange="platformCertificate"
                @handleRemove="handleRemove('platformCertUrl')"></ykc-upload>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="ApiV3Key" prop="wxApiv3key">
              <ykc-input
                v-model="ruleForm.wxApiv3key"
                maxlength="30"
                placeholder="请输入ApiV3Key"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="微信服务ID" prop="wechatId">
              <ykc-input
                v-model="ruleForm.wechatId"
                maxlength="30"
                placeholder="请输入微信服务ID"></ykc-input>
            </ykc-form-item>
          </template>
        </ykc-detail-plus>
        <ykc-detail-plus class="box-card">
          <div slot="header" class="clearfix-title">
            <span>支付宝免密支付配置</span>
          </div>
          <ykc-form-item prop="aliEnable" label="是否启用免密" required>
            <ykc-radio :data="enableList" v-model="ruleForm.aliEnable"></ykc-radio>
          </ykc-form-item>
          <template v-if="ruleForm.aliEnable === '0'">
            <ykc-form-item class="form-item-width" label="AppId" prop="aliAppId">
              <ykc-input
                v-model="ruleForm.aliAppId"
                maxlength="30"
                placeholder="请输入AppId"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="小程序AppId" prop="aliAppletAppid">
              <ykc-input
                v-model="ruleForm.aliAppletAppid"
                maxlength="30"
                placeholder="请输入小程序AppId"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="公钥" prop="aliPublicCrack">
              <ykc-input
                v-model="ruleForm.aliPublicCrack"
                maxlength="500"
                type="textarea"
                placeholder="请输入公钥"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="私钥" prop="aliPrivateCrack">
              <ykc-input
                v-model="ruleForm.aliPrivateCrack"
                maxlength="500"
                type="textarea"
                placeholder="请输入私钥"></ykc-input>
            </ykc-form-item>

            <ykc-form-item class="form-item-width" label="小程序授权令牌" prop="aliAuthToken">
              <ykc-input
                v-model="ruleForm.aliAuthToken"
                maxlength="30"
                placeholder="请输入小程序授权令牌"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="芝麻服务ID" prop="aliSesameId">
              <ykc-input
                v-model="ruleForm.aliSesameId"
                maxlength="30"
                placeholder="请输入芝麻服务ID"></ykc-input>
            </ykc-form-item>
            <ykc-form-item class="form-item-width" label="PID" prop="aliPid">
              <ykc-input
                v-model="ruleForm.aliPid"
                maxlength="30"
                placeholder="请输入PID"></ykc-input>
            </ykc-form-item>
          </template>
        </ykc-detail-plus>
      </div> -->
      <div class="btn-footer">
        <ykc-button v-rbac="'sys:config:save'" @click="submitForm">保存</ykc-button>
      </div>
    </ykc-form>
  </scroll-layout>
</template>

<script>
  import { mapMutations } from 'vuex';
  import { sysCnf, uploadUtils } from '@/service/apis';
  import regexpObj from '@/utils/regexp';
  // import { localSetItem, sessionSetItem } from '@/utils';

  export default {
    name: 'systemConfigList',
    data() {
      return {
        enableList: [
          {
            id: '0',
            name: '启用',
          },
          {
            id: '1',
            name: '不启用',
          },
        ],
        activeTheme: 0,
        themeList: [
          {
            color: '#4D8CFD',
          },
        ],
        alipayDiscount: [
          {
            id: '0',
            name: '无优惠',
          },
          {
            id: '1',
            name: '有优惠',
          },
        ],
        wxDiscount: [
          {
            id: '0',
            name: '无优惠',
          },
          {
            id: '1',
            name: '有优惠',
          },
        ],
        ruleForm: {
          sysConfBaseId: '',
          mainColor: '#4D8CFD',
          logo: '',
          loginLogo: '',
          wxDiscountType: '0',
          alipayDiscountType: '0',
          wxDiscount: '',
          alipayDiscount: '',
          wxAppId: '',
          wechatConfId: '',
          wxAppCrack: '',
          wxAppletAppid: '',
          wxAppletAppidCrack: '',
          wxMerchantNo: '',
          wxMerchantCrack: '',
          authCertUrl: '',
          wxMerchantPrivateCrack: '',
          wxApiCertSeq: '',
          platformCertUrl: '',
          wxApiv3key: '',
          wechatId: '',
          wxEnable: '1',
          aliAppId: '',
          alipayConfId: '',
          aliAppletAppid: '',
          aliPublicCrack: '',
          aliPrivateCrack: '',
          aliAuthToken: '',
          aliSesameId: '',
          aliPid: '',
          aliEnable: '1',
          discountList: [],
        },
        rules: {
          mainColor: [{ required: true }],
          logo: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.logoFile.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请上传LOGO'));
                }
              },
            },
          ],
          loginLogo: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.loginLogoFile.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请上传登录页LOGO'));
                }
              },
            },
          ],
          wxAppId: [
            {
              required: true,
              message: '请输入AppId',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('AppId'),
            },
          ],
          wxAppCrack: [
            {
              required: true,
              message: '请输入App秘钥',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.secretType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.secretType.errorTips.error('App秘钥'),
            },
          ],
          wxAppletAppid: [
            {
              required: true,
              message: '请输入小程序AppId',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('小程序AppId'),
            },
          ],
          wxAppletAppidCrack: [
            {
              required: true,
              message: '请输入小程序秘钥',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.secretType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.secretType.errorTips.error('小程序秘钥'),
            },
          ],
          wxMerchantNo: [
            {
              required: true,
              message: '请输入商户号',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('商户号'),
            },
          ],
          wxMerchantCrack: [
            {
              required: true,
              message: '请输入商户秘钥',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.secretType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.secretType.errorTips.error('商户秘钥'),
            },
          ],
          authCertificate: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.authCertificate.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要上传的文件'));
                }
              },
            },
          ],
          platformCertificate: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.platformCertificate.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要上传的文件'));
                }
              },
            },
          ],
          wxMerchantPrivateCrack: [
            {
              required: true,
              message: '请输入商户私钥',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.secretType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.secretType.errorTips.error('商户私钥'),
            },
          ],
          wxApiCertSeq: [
            {
              required: true,
              message: '请输入API证书序号',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('API证书序号'),
            },
          ],
          wxApiv3key: [
            {
              required: true,
              message: '请输入ApiV3Key',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('ApiV3Key'),
            },
          ],
          wechatId: [
            {
              required: true,
              message: '请输入微信服务ID',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('微信服务ID'),
            },
          ],
          aliAppId: [
            {
              required: true,
              message: '请输入AppId',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('AppId'),
            },
          ],
          aliAppletAppid: [
            {
              required: true,
              message: '请输入小程序AppId',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('小程序AppId'),
            },
          ],
          aliPublicCrack: [
            {
              required: true,
              message: '请输入公钥',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.secretType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.secretType.errorTips.error('公钥'),
            },
          ],
          aliPrivateCrack: [
            {
              required: true,
              message: '请输入私钥',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.secretType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.secretType.errorTips.error('私钥'),
            },
          ],
          aliAuthToken: [
            {
              required: true,
              message: '请输入小程序授权令牌',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('小程序授权令牌'),
            },
          ],
          aliSesameId: [
            {
              required: true,
              message: '请输入芝麻服务ID',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('芝麻服务ID'),
            },
          ],
          aliPid: [
            {
              required: true,
              message: '请输入PID',
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('PID'),
            },
          ],
        },
        authCertUrlData: {
          authCertName: '',
          authCertUrl: '',
        },
        platformCertUrlData: {
          platformCertName: '',
          platformCertUrl: '',
        },
      };
    },
    created() {
      this.getDetail();
    },
    methods: {
      ...mapMutations(['setLogo']),
      changeWxDiscount(val) {
        if (val !== '1') {
          this.ruleForm.wxDiscount = '';
        }
      },
      changeAliDiscount(val) {
        if (val !== '1') {
          this.ruleForm.alipayDiscount = '';
        }
      },
      changeWxEnable(val) {
        if (val === '0') {
          this.$nextTick(() => {
            if (this.authCertUrlData.authCertUrl && this.$refs.authCertificate) {
              this.ruleForm.authCertUrl = this.authCertUrlData.authCertUrl || '';
              this.$refs.authCertificate.fileList = [
                {
                  name: this.authCertUrlData.authCertName || '授权证书',
                  url: this.authCertUrlData.authCertUrl,
                },
              ];
            }
            if (this.platformCertUrlData.platformCertUrl && this.$refs.platformCertificate) {
              this.ruleForm.platformCertUrl = this.platformCertUrlData.platformCertUrl || '';
              this.$refs.platformCertificate.fileList = [
                {
                  name: this.platformCertUrlData.platformCertName || '平台证书',
                  url: this.platformCertUrlData.platformCertUrl,
                },
              ];
            }
          });
        }
      },
      getDetail() {
        sysCnf.detail({}).then(res => {
          if (res?.sysConfBase) {
            if (res.sysConfBase?.logoPath) {
              this.ruleForm.logo = res.sysConfBase.logoPath || '';
              this.$refs.logoFile.fileList = [{ url: res.sysConfBase.logoPath }];
            }
            if (res.sysConfBase?.loginLogoPath) {
              this.ruleForm.loginLogo = res.sysConfBase.loginLogoPath || '';
              this.$refs.loginLogoFile.fileList = [{ url: res.sysConfBase.loginLogoPath }];
            }
            this.ruleForm.sysConfBaseId = res.sysConfBase.id || '';
            this.ruleForm.mainColor = res.sysConfBase.mainColor || '';
            this.ruleForm.wxDiscountType = res.sysConfBase.wxpayCouponEnable || '0';
            this.ruleForm.alipayDiscountType = res.sysConfBase.alipayCouponEnable || '0';
            this.ruleForm.wxDiscount =
              res.sysConfBase.wxpayCouponEnable === '1'
                ? res.sysConfBase.wxpayCouponDesc || ''
                : '';
            this.ruleForm.alipayDiscount =
              res.sysConfBase.alipayCouponEnable === '1'
                ? res.sysConfBase.alipayCouponDesc || ''
                : '';
          }
          if (res?.wechatConf) {
            this.ruleForm.wxAppId = res.wechatConf.appId || '';
            this.ruleForm.wechatConfId = res.wechatConf.id || '';
            this.ruleForm.wxAppCrack = res.wechatConf.appCrack || '';
            this.ruleForm.wxAppletAppid = res.wechatConf.appletAppid || '';
            this.ruleForm.wxAppletAppidCrack = res.wechatConf.appletAppidCrack || '';
            this.ruleForm.wxMerchantNo = res.wechatConf.merchantNo || '';
            this.ruleForm.wxMerchantCrack = res.wechatConf.merchantCrack || '';
            this.ruleForm.wxMerchantPrivateCrack = res.wechatConf.merchantPrivateCrack || '';
            this.ruleForm.wxApiCertSeq = res.wechatConf.apiCertSeq || '';
            this.ruleForm.wxApiv3key = res.wechatConf.apiv3key || '';
            this.ruleForm.wechatId = res.wechatConf.wechatId || '';
            this.ruleForm.wxEnable = res.wechatConf.freePay || '1';
            this.authCertUrlData.authCertName = res.wechatConf.authCertName || '';
            this.authCertUrlData.authCertUrl = res.wechatConf.authCertUrl || '';
            this.platformCertUrlData.platformCertName = res.wechatConf.platformCertName || '';
            this.platformCertUrlData.platformCertUrl = res.wechatConf.platformCertUrl || '';
            this.$nextTick(() => {
              if (res.wechatConf.authCertUrl && this.$refs.authCertificate) {
                this.ruleForm.authCertUrl = res.wechatConf.authCertUrl || '';
                this.$refs.authCertificate.fileList = [
                  {
                    name: res.wechatConf.authCertName || '授权证书',
                    url: res.wechatConf.authCertUrl,
                  },
                ];
              }
              if (res.wechatConf.platformCertUrl && this.$refs.platformCertificate) {
                this.ruleForm.platformCertUrl = res.wechatConf.platformCertUrl || '';
                this.$refs.platformCertificate.fileList = [
                  {
                    name: res.wechatConf.platformCertName || '平台证书',
                    url: res.wechatConf.platformCertUrl,
                  },
                ];
              }
            });
          }
          if (res?.alipayConf) {
            this.ruleForm.aliAppId = res.alipayConf.appId || '';
            this.ruleForm.alipayConfId = res.alipayConf.id || '';
            this.ruleForm.aliAppletAppid = res.alipayConf.appletAppid || '';
            this.ruleForm.aliPublicCrack = res.alipayConf.publicCrack || '';
            this.ruleForm.aliPrivateCrack = res.alipayConf.privateCrack || '';
            this.ruleForm.aliAuthToken = res.alipayConf.authToken || '';
            this.ruleForm.aliSesameId = res.alipayConf.sesameId || '';
            this.ruleForm.aliPid = res.alipayConf.pid || '';
            this.ruleForm.aliEnable = res.alipayConf.freePay || '1';
          }
        });
      },
      // 清除证书
      handleRemove(propName) {
        this.ruleForm[propName] = '';
        if (propName === 'authCertUrl') {
          this.authCertUrlData.authCertName = '';
          this.authCertUrlData.authCertUrl = '';
        } else if (propName === 'platformCertUrl') {
          this.platformCertUrlData.platformCertName = '';
          this.platformCertUrlData.platformCertUrl = '';
        }
      },
      /** 导入授权证书 */
      authCertificate(file, fileList, isCheck) {
        console.log(file, fileList, isCheck);
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadFile(formData).then(res => {
            this.authCertUrlData.authCertName = file.name;
            this.authCertUrlData.authCertUrl = res;
            this.ruleForm.authCertUrl = res;
            this.$refs.authCertificate.fileList = [
              {
                name: file.name,
                url: res,
              },
            ];
            this.$refs.ruleForm.validateField('authCertificate');
          });
        }
      },
      /** 导入平台证书 */
      platformCertificate(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadFile(formData).then(res => {
            this.platformCertUrlData.platformCertName = file.name;
            this.platformCertUrlData.platformCertUrl = res;
            this.ruleForm.platformCertUrl = res;
            this.$refs.platformCertificate.fileList = [
              {
                name: file.name,
                url: res,
              },
            ];
            this.$refs.ruleForm.validateField('platformCertificate');
          });
        }
      },
      // 切换主色
      changeTheme(index) {
        this.activeTheme = index;
        this.ruleForm.theme = this.themeList[index].color;
      },
      /**
       * 删除属性
       */
      delProperty(obj, ps) {
        ps.forEach(str => Reflect.deleteProperty(obj, str));
      },
      // 表单提交
      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const delArr = [];
            if (this.ruleForm.wxEnable === '1') {
              delArr.push(
                'wxAppId',
                'wxAppCrack',
                'wxAppletAppid',
                'wxAppletAppidCrack',
                'wxMerchantNo',
                'wxMerchantCrack',
                'authCertificate',
                'wxMerchantPrivateCrack',
                'wxApiCertSeq',
                'platformCertificate',
                'wxApiv3key',
                'wechatId'
              );
            }
            if (this.ruleForm.aliEnable === '1') {
              delArr.push(
                'aliAppId',
                'aliAppletAppid',
                'aliPublicCrack',
                'aliPrivateCrack',
                'aliAuthToken',
                'aliSesameId',
                'aliPid'
              );
            }
            this.delProperty(this.ruleForm, delArr);
            console.log('this.ruleForm', this.ruleForm);
            const reqParams = {
              sysConfBase: {
                mainColor: this.ruleForm.mainColor,
                logoPath: this.ruleForm.logo,
                id: this.ruleForm.sysConfBaseId,
                loginLogoPath: this.ruleForm.loginLogo,
                langDictCode: 'zh_CN',
                wxpayCouponEnable: this.ruleForm.wxDiscountType,
                alipayCouponEnable: this.ruleForm.alipayDiscountType,
                wxpayCouponDesc: this.ruleForm.wxDiscount,
                alipayCouponDesc: this.ruleForm.alipayDiscount,
              },
              wechatConf: {
                appId: this.ruleForm.wxAppId || '',
                id: this.ruleForm.wechatConfId || '',
                appCrack: this.ruleForm.wxAppCrack || '',
                appletAppid: this.ruleForm.wxAppletAppid || '',
                appletAppidCrack: this.ruleForm.wxAppletAppidCrack || '',
                merchantNo: this.ruleForm.wxMerchantNo || '',
                merchantCrack: this.ruleForm.wxMerchantCrack || '',
                authCertUrl: this.ruleForm.authCertUrl || '',
                merchantPrivateCrack: this.ruleForm.wxMerchantPrivateCrack || '',
                apiCertSeq: this.ruleForm.wxApiCertSeq || '',
                platformCertUrl: this.ruleForm.platformCertUrl || '',
                apiv3key: this.ruleForm.wxApiv3key || '',
                wechatId: this.ruleForm.wechatId || '',
                freePay: this.ruleForm.wxEnable,
              },
              alipayConf: {
                appId: this.ruleForm.aliAppId || '',
                id: this.ruleForm.alipayConfId || '',
                appletAppid: this.ruleForm.aliAppletAppid || '',
                publicCrack: this.ruleForm.aliPublicCrack || '',
                privateCrack: this.ruleForm.aliPrivateCrack || '',
                authToken: this.ruleForm.aliAuthToken || '',
                sesameId: this.ruleForm.aliSesameId || '',
                pid: this.ruleForm.aliPid || '',
                freePay: this.ruleForm.aliEnable,
              },
            };
            console.log('reqParams', reqParams);
            sysCnf.save(reqParams).then(res => {
              console.log(res);
              this.setLogo(this.ruleForm.logo);
              this.$message({
                message: '保存成功',
                type: 'success',
              });
            });
          }
        });
      },
      // logo上传
      fileUpload(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.logo = res;
            this.$refs.ruleForm.validateField('logo');
          });
        }
      },
      // logo删除
      handleRemoveLogo() {
        this.ruleForm.logo = undefined;
      },
      // 登录logo上传
      fileUploadLogin(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          console.log('param', param);
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.loginLogo = res;
            this.$refs.ruleForm.validateField('loginLogo');
          });
        }
      },
      // 登录logo删除
      handleRemoveLoginLogo() {
        this.ruleForm.loginLogo = undefined;
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .scroll-layout-header {
    display: none;
  }
  .theme-ul {
    display: flex;
    flex-wrap: wrap;
    .theme-li {
      box-sizing: border-box;
      width: 80px;
      height: 30px;
      border-radius: 2px;
      margin-right: 8px;
      margin-bottom: 8px;
      &-active {
        border: 1px solid #000000;
      }
    }
  }
  .btn-footer {
    padding-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  .content {
    display: flex;
    .box-card {
      width: 50%;
      box-sizing: border-box;
      margin: 20px 8px;
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
    ::v-deep .el-card__body {
      padding: 20px 40px;
    }
    .content-card {
      width: 100%;
      margin-bottom: 6px;
    }
  }

  .groupInfo {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: calc(50% - 10px) !important;
      margin: 0 5px;
    }
    ::v-deep .ykc-form-item .el-form-item {
      margin-bottom: 10px !important;
    }
  }
  .item-bottom {
    max-width: 500px;
    & > ::v-deep .el-form-item {
      margin-bottom: 2px !important;
    }
  }

  ::v-deep.ykc-form .file-error .tips-msg {
    margin-top: 0 !important;
  }
  .form-item-width {
    max-width: 480px;
  }
  ::v-deep .clearfix {
    padding-left: 0;
    &::before {
      display: none;
    }
  }
  .clearfix-title {
    padding: 10px;
    position: relative;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    &::after {
      content: ' ';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #e2e0e0;
    }
  }
  .login-logo-custom {
    ::v-deep .ykc-upload.uploaded-picture {
      width: 180px;
      height: 46px;
    }
    ::v-deep .ykc-upload .el-upload-list--picture .el-upload-list__item {
      width: 180px;
      height: 46px;
    }
  }
</style>
